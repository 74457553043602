export const ToolTipIcon = () => {
    return (
        <svg
        viewBox="0 0 24 24"
        width="1.25em"
        height="1.2em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11,22C4.9,22,0,17.1,0,11S4.9,0,11,0s11,4.9,11,11C22,17.1,17.1,22,11,22z M11,7c0.6,0,1-0.4,1-1s-0.4-1-1-1
      s-1,0.4-1,1S10.4,7,11,7z M11,17c0.6,0,1-0.4,1-1l0-6c0-0.6-0.4-1-1-1s-1,0.4-1,1l0,6C10,16.6,10.5,17,11,17z"
          fill="currentColor"
        />
      </svg>
    );
  };
  