export const TrashIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 3C9.58579 3 9.25 3.33579 9.25 3.75V4.5H5.75C5.33579 4.5 5 4.83579 5 5.25C5 5.66421 5.33579 6 5.75 6H18.25C18.6642 6 19 5.66421 19 5.25C19 4.83579 18.6642 4.5 18.25 4.5H14.75V3.75C14.75 3.33579 14.4142 3 14 3H10Z"
        fill="#407196"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7H18V18.25C18 19.7688 16.7688 21 15.25 21H8.75C7.23122 21 6 19.7688 6 18.25V7ZM7.5 8.5V18.25C7.5 18.9404 8.05964 19.5 8.75 19.5H15.25C15.9404 19.5 16.5 18.9404 16.5 18.25V8.5H7.5Z"
        fill="#407196"
      />
    </svg>
  );
};
