// export {default as deleteperson} from './deleteperson.svg'

export const DeletePersonIcon = () => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 49.8 64.4">
      <path d="M46,38.9c-3.4-2.3-8.6-3.8-14.5-4.6v-3.2c4.9-1.7,8-5.8,8-10.6v-4.6c0-6.6-5.5-11.5-13.3-11.5 s-13.4,5-13.4,11.4v4.6c0,5,3.1,8.9,8,10.6v3.2C10.5,35.8,2.3,39,2.3,45.9v9.2" />
      <circle cx="36.7" cy="51.3" r="10.9" />
      <line x1="31.3" y1="45.9" x2="42" y2="56.7" />
      <line x1="42" y1="45.9" x2="31.3" y2="56.7" />
    </svg>
  );
};
